<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer" />
      <div slot="content">
        <!-- <input type="text" v-model="filters.name.value"> -->

        <div class="container">
                <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div>
          <div style="display:flex;align-items:center; min-height:80vh" class="pt-1">
            <div class="vest-box">
                <div class="text-center">
                  <img src="../../../assets/images/vestf.png" height="40" v-if="windowWidth < 767">
                </div>

              <div class="img-div">
                <img src="../../../assets/images/vestBg.png" height="300" v-if="windowWidth > 445">
                <img src="../../../assets/images/vestBg.png" height="150" v-if="windowWidth < 445">
              </div>
              <div class="text-div">
                <img src="../../../assets/images/vestlock.png" height="40" v-if="windowWidth > 767">

                <p class="mb-4 mt-4">
                  <i class="fa fa-circle pr-2" style="color:#F2A800;"></i>Invest Funds
                </p>
                <div></div>
                <h4 class="mb-4">
                  <i class="fa fa-circle pr-2" style="color:#58B46F"></i>Get interest upfront
                </h4>
                <p class="mb-4">
                  <i class="fa fa-circle pr-2" style="color:#0094FF"></i>Get capital at maturity
                </p>

                <div class="vest-btn point-cursor" @click="sendToAmountView">
                  <div class="first-div">
                    <h6>
                      <b>Create a Vestlock</b>
                    </h6>
                    <p class="pt-2" style="color:#828282" v-if="vestPortfolio.length > 1"> {{vestPortfolio.length}} lockdowns</p>
                    <p class="pt-2" style="color:#828282" v-else>{{vestPortfolio.length}} lockdown</p>
                 
                  </div>

                  <div class="sec-div text-right">
                    <img src="../../../assets/images/vestlock2.png" height="30">
                  </div>
                </div>
                <div class="vest-btn mt-3 point-cursor" @click="sendToVestExplainer" style="min-height:50px; border:1px solid #F2A800">
                  <div class="first-div">
                    <h6>
                      <b>See what it’s all about</b>
                    </h6>
                  </div>

                  <div class="sec-div text-right">
                    <img src="../../../assets/images/iconi.png" height="25">
                  </div>
                </div>
                <div style="max-width: 350px;"  @click="gotoVestPortfolio">

                <p class="underline text-center mt-4"><b>View your Vestlock portfolio</b></p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>

  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";

import moment from "moment";

export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {}
    };
  },
  methods: {
    sendToMarketPlace() {

      this.$router.push("/market");
    },
    sendToAmountView() {

        this.$store.commit("auth/saveVestRouteVisited", "/vest/amount")
      this.$router.push("/vest/amount");
    },
     gotoVestPortfolio() {
      this.$router.push("/vest/portfolio");
      this.$store.commit("auth/saveVestRouteVisited", "/vest/portfolio");
    },
     sendToVestExplainer() {
      this.$router.push("/vest/explainer");
     
    },
  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper
  },

  computed: {
    ...mapState({
      userBal: state => state.auth.userProfileInfo.balance,
      vestRouteVisited: state => state.auth.vestRouteVisited,
            vestPortfolio: state => state.market.vestPortfolio,
    }),

    returnAmountOfPenalty() {
      return this.penaltyAmount;
    },

  },
  mounted() {
    let heading = {
      main: this.tran.vest_lock
    };
     localStorage.setItem("vestLockDataFromLocalStorage", false )
    this.$store.commit("market/savePageHeading", heading);
     this.$store.dispatch("market/getVestPortfolio");
     
   localStorage.setItem("investAmount",false)
  },

  beforeCreate() {
    this.$store.dispatch("market/getPortfolio");
    this.$store.commit("market/checkSearchObject", "portfolio");
  },

  beforeDestroy() {
    this.$store.commit("market/startPreloader");

    this.$store.commit("market/startIsFetching");
    this.$store.commit("market/removePageHeading");
  }
};
</script>

<style scoped>
</style>
