<template>
<div>
    <vestHome />
</div>
</template>

<script>
import vestHome from '../../components/dashboard/vestfolder/VestLockHome.vue'
export default {
    components: {
        vestHome
    }
}
</script>

